import { Button, Dialog, Fab } from "@mui/material";
import React, { useEffect, useState } from "react";
import Repairs from "./repairs";
import CloseIcon from "@mui/icons-material/Close";
export default function DialogRepairs() {
	const [open, setOpen] = useState(false);
	useEffect(() => {
		const path = window.location.pathname;
		if (path === "/view") {
			setOpen(true);
		}
	}, []);
	return (
		<>
			<Dialog
				fullScreen={true}
				open={open}
				onClose={() => {
					setOpen(!open);
				}}>
				<Fab
					onClick={() => {
						setOpen(!open);
					}}
					sx={{
						margin: 0,
						top: 20,
						right: 20,
						bottom: "auto",
						left: "auto",
						position: "fixed",
					}}
					color="primary"
					aria-label="add">
					<CloseIcon />
				</Fab>
				<Repairs />
			</Dialog>
		</>
	);
}
