import React, { useState } from "react";
import { Box, Typography, Card, CardContent, Grid, Paper } from "@mui/material";

const CarouselCard = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	let items = [
		{
			title: "2003",
			description:
				"A Sattune nasceu em 2003  no concelho de Esposende tendo especial incidência na reparação de pequenos eletrodomésticos e na manutenção e reparação de antenas terrestre e satélite.",
		},
		{
			title: "2008",
			description:
				"Alargamos os nossos conceitos para a comercialização de produtos da operadora ZON (agora NOS) com a abertura de uma loja exclusiva.",
		},
		{
			title: "2012-2017",
			description:
				"Entre 2012 e 2017 incorpora-mos na nossa equipa técnicos de HFC (cabo) DTH (satélite) e FTTH(Fibra) para a zona de Esposende e Barcelos.",
		},
		{
			title: "2020-Hoje",
			description:
				"Em 2020/2021 Apostamos nos setores mais actuais, domótica(IOT) Energia Renovavel e Programação web",
		},
	];
	const handleCardClick = (index) => {
		setActiveIndex(index);
	};

	return (
		<Box backgroundColor="#111" sx={{ width: "100vw", height: "100vh" }}>
			<Typography textAlign="center" pt="10vh" color="white" variant="h3">
				O nosso Percurso
			</Typography>

			<Grid
				container
				spacing={3}
				justifyContent="space-around"
				width="100vw"
				height="80vh"
				alignItems="center">
				{items.map((item, index) => (
					<Grid item width="20vw" key={index}>
						<Paper
							elevation={activeIndex === index ? 6 : 3}
							sx={{
								transform: activeIndex === index ? "scale(1.1)" : "scale(1)",
								transition: "transform 0.3s ease-in-out",
								backgroundColor: "rgba(0,0,0,0)",
								borderRadius: "20px",
								height: "40vh",
								width: "20vw",
							}}
							onClick={() => handleCardClick(index)}>
							<Card
								sx={{
									height: "40vh",
									maxHeight: "80vh",
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
									borderRadius: "20px",
									width: "20vw",
									overflow: "hidden",
									perspective: "3000px",
									transformStyle: "preserve-3d",
									background: "#fff",
									boxShadow:
										"0 8px 16px -8px rgba(0,0,0,0.4), 0 0 0 1px rgba(0,0,0,0.1)",
								}}>
								<Box
									sx={{
										position: "absolute",
										top: 0,
										left: 0,
										right: 0,
										bottom: 0,
										background: "#fff",
										transform: "translateZ(-1px)",
										zIndex: -1,
									}}></Box>
								<Box
									sx={{
										padding: "20px",
										position: "relative",
										transform:
											activeIndex === index ? "translateZ(30px)" : "none",
										zIndex: 1,
										flexGrow: 1,
									}}>
									<CardContent>
										<Typography variant="h5" component="h2">
											{item.title}
										</Typography>
										<Typography variant="subtitle1" component="h2">
											{item.description}
										</Typography>
									</CardContent>
								</Box>
							</Card>
						</Paper>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default CarouselCard;
