import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Import your stub logos here
var logo1 = require("../media/sattune-banner-no-bg.png");
var logo2 = require("../media/xd_logo-1.png");

// Define the styles for the component using Material-UI's `styled` API
const AnnouncementContainer = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(2),

	backgroundColor: "#111",
	boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
	"& > *": {
		marginRight: theme.spacing(2),
	},
}));

const Logo = styled("img")({
	height: 40,
	objectFit: "contain",
});

export default function Announcement() {
	return (
		<AnnouncementContainer>
			<Typography variant="subtitle1" color="white" component="div">
				Revendedores oficiais
			</Typography>
			<Logo src={logo2} alt="Partner Logo 2" />
		</AnnouncementContainer>
	);
}
