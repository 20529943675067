import { CircularProgress, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { MouseParallax, ScrollParallax } from "react-just-parallax";
import useGoogleSheets from "use-google-sheets";
import Papa from "papaparse";
async function enviarParaDiscord(tal) {
	await axios.post(
		"https://discord.com/api/webhooks/1205119325741129739/oijQVodEnqX-9QevAoMm_LJ4S5Q6FTXpVHhjMcx908NoleNt_S9bQppPxeUx6kAh39iQ",
		{
			content: "Nova pesquisa de talão",
			embeds: [
				{
					title: `Nova pesquisa de talão`,
					description: `o talão com o numero ${tal} foi pesquisado`,
					color: 65331,
					author: {
						name: `Sattune`,
					},
					footer: {
						text: `Pesquisa feita no website`,
					},
				},
			],
			attachments: [],
		}
	);
}
export default function Repairs() {
	const [val, setVal] = useState();
	const [info, setInfo] = useState();

	const fetch = () => {
		const url = `https://my-teck.com:5000/check-talao/${parseInt(val)}`;
		axios
			.get(url)
			.then((response) => {
				setInfo(response.data[0]);
				enviarParaDiscord(val);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log("Error data:", error.response.data);
					console.log("Error status:", error.response.status);
					console.log("Error headers:", error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					console.log("Error request:", error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log("Error message:", error.message);
				}
				console.log("Error config:", error.config);
			});
	};
	useEffect(() => {
		if (val > 10000) {
			fetch();
		} else {
			setInfo(null);
		}
	}, [val]);

	return (
		<Grid
			container
			width="100vw"
			backgroundColor="#111"
			direction="row"
			justifyContent={"center"}
			alignContent={"center"}
			height="100vh">
			<Grid item>
				<TextField
					label="Numero do talão"
					inputProps={{
						style: {
							color: "white",
							backgroundColor: "rgba(255,255,255,0.4)",
						},
					}}
					value={val || ""}
					onChange={(e) => {
						setVal(e.target.value);
					}}
				/>
			</Grid>
			{!!info && (
				<Grid item width="50vw">
					<Typography
						variant="h4"
						textAlign={"center"}
						color={
							!info.status || info.status === "SEM REPARAÇÃO"
								? "#B36059"
								: "#4CAF50"
						}>
						{info.status || "Não reparado"}
					</Typography>
				</Grid>
			)}
			{!info && (
				<Grid item width="50vw">
					<Typography variant="h4" textAlign={"center"} color="white">
						{" "}
						{"Ainda sem informação"}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
}
