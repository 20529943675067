import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Main from "./components/main";
import DialogRepairs from "./components/popup";
import CompanysScreen from "./screens/companys";
import AboutUs from "./screens/aboutUs";
import CarouselCard from "./components/card";
import Announcement from "./components/announcements";
import { Box } from "@mui/material";
import RepairsButton from "./screens/repairs";
import { isMobile } from "react-device-detect";
import CompanysMobileScreen from "./screens/companys _mobile";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<React.StrictMode>
		{isMobile ? (
			<Box width={"100vw"} sx={{ overflowX: "hidden" }}>
				<Main />
				<RepairsButton />
				<CompanysMobileScreen />
				<AboutUs />

				<Announcement />
			</Box>
		) : (
			<Box width={"100vw"} sx={{ overflowX: "hidden" }}>
				<Main />
				<RepairsButton />
				<CompanysScreen />
				<AboutUs />
				<CarouselCard />
				<Announcement />
			</Box>
		)}
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
