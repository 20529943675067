import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ParticlesBg from "particles-bg";
import { MouseParallax, ScrollParallax } from "react-just-parallax";
import MouseIcon from "@mui/icons-material/Mouse";
var logo = require("../media/sattune-banner-no-bg.png");
let arr = [
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
	1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2,
];
const generate = () => {
	return Math.floor(Math.random() * 10);
};
const generateStarSize = () => {
	return Math.floor(Math.random() * 4);
};

export default function Main() {
	return (
		<Grid
			position="relative"
			container
			width="100vw"
			direction="column"
			backgroundColor="#111"
			justifyContent={"center"}
			alignContent={"center"}
			height="120vh">
			<Box width="100vw" height="120vh" position="absolute" overflow="hidden">
				<Grid
					position="relative"
					container
					direction="column"
					width="100vw"
					justifyContent={"space-around"}
					alignContent={"center"}
					height="120vh">
					{arr.map((a, index) => {
						let mleft = generate();
						let mtop = generate();
						let starSize = generateStarSize();
						if (index % 2 === 0)
							return (
								<Grid item>
									<MouseParallax strength={a / 5}>
										<Box
											mt={mtop}
											ml={mleft}
											backgroundColor="rgba(255,255,255,0.5)"
											borderRadius="20px"
											width={`${starSize}px`}
											height={`${starSize}px`}></Box>
									</MouseParallax>
								</Grid>
							);
						else
							return (
								<Grid item>
									<Box
										mt={mtop}
										ml={mleft}
										backgroundColor="rgba(255,255,255,0.5)"
										borderRadius="20px"
										width={`${starSize}px`}
										height={`${starSize}px`}></Box>
								</Grid>
							);
					})}
				</Grid>
			</Box>
			<Grid item width="50vw">
				<MouseParallax strength={0.05}>
					<img src={logo} width="100%"></img>
				</MouseParallax>
			</Grid>

			<Typography
				textAlign={"center"}
				pt={"20vh"}
				color="rgba(30, 44, 226,0.5)">
				<b>scroll down</b>
				<br></br>
				<MouseIcon />
			</Typography>
		</Grid>
	);
}
