import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function AboutUs() {
	let texts = [
		"Técnica",
		"Programação",
		"Paines Solares",
		"CCTV",
		"Reparações",
		"Operadoras",
		"Domótica",
	];
	let interval = 2000;
	const [index, setIndex] = useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			setIndex((index) => (index + 1) % texts.length);
		}, interval);

		return () => {
			clearInterval(timer);
		};
	}, [interval, texts.length]);
	return (
		<Grid
			container
			direction="column"
			width="100vw"
			height="50vh"
			justifyContent={"center"}
			alignItems={"center"}
			backgroundColor="#dbdddb">
			<Grid item>
				<Typography variant="h3" textAlign={"center"}>
					{" "}
					As nossas areas de atuação
				</Typography>
			</Grid>
			<Grid item mt={5}></Grid>
			<Grid item>
				<Typography variant="h3">
					<b>{texts[index]}</b>
				</Typography>
			</Grid>
		</Grid>
	);
}
