import { Box, Grid } from "@mui/material";

import DialogRepairs from "../components/popup";

const RepairsButton = () => {
	return (
		<Box width="100vw" height={"30vh"} backgroundColor="#111">
			<Grid
				width="100%"
				height="100%"
				container
				direction="row"
				justifyContent="center"
				alignItems="center">
				<Grid item>
					<DialogRepairs />
				</Grid>
			</Grid>
		</Box>
	);
};
export default RepairsButton;
