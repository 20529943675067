import {
	Box,
	Grid,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import React from "react";
import { MouseParallax, ScrollParallax } from "react-just-parallax";
import { ParallaxHover } from "react-parallax-hover";
import DialogRepairs from "../components/popup";

var logoEpromx = require("../media/epromx_logo.png");
var logoNos = require("../media/nexteam_white.png");
var logoNexteam = require("../media/nos.png");
var logoDomoteck = require("../media/domoteck.png");

export default function CompanysScreen() {
	return (
		<Box width="100vw" height="50vh" backgroundColor="#dbdddb">
			<ScrollParallax strength={0.05} zIndex={0}>
				<Grid
					container
					direction="row"
					justifyContent={"space-around"}
					alignContent="center"
					width="100vw"
					height="50vh">
					<Grid
						item
						onClick={() => (window.location.href = "https://www.domoteck.pt")}>
						<ScrollParallax strength={0.05} zIndex={0}>
							<ParallaxHover
								width={250}
								height={300}
								borderRadius={10}
								shine={1}
								rotation={9}>
								<Grid
									container
									direction="column"
									width={250}
									height={300}
									backgroundColor="#111"
									alignContent={"center"}
									justifyContent="space-around"
									paddingLeft={"18%"}>
									<Grid item>
										<img src={logoDomoteck} width="75%"></img>
									</Grid>
								</Grid>
							</ParallaxHover>
						</ScrollParallax>
					</Grid>
					<Grid
						item
						onClick={() => (window.location.href = "https://www.epromx.com")}>
						<ScrollParallax strength={0.05} zIndex={0}>
							<ParallaxHover
								width={250}
								height={300}
								borderRadius={10}
								shine={1}
								rotation={9}>
								<Grid
									container
									direction="column"
									width={250}
									height={300}
									backgroundColor="#111"
									alignContent={"center"}
									justifyContent="space-around"
									paddingLeft={"18%"}>
									<Grid item>
										<img src={logoEpromx} width="75%"></img>
									</Grid>
								</Grid>
							</ParallaxHover>
						</ScrollParallax>
					</Grid>
					<Grid
						item
						onClick={() => (window.location.href = "https://www.nexteam.pt")}>
						<ScrollParallax strength={0.05} zIndex={0}>
							<ParallaxHover
								width={250}
								height={300}
								borderRadius={10}
								shine={1}
								rotation={9}>
								<Grid
									container
									direction="column"
									width={250}
									height={300}
									backgroundColor="#111"
									alignContent={"center"}
									justifyContent="space-around"
									paddingLeft={"18%"}>
									<Grid item>
										<img src={logoNos} width="75%"></img>
									</Grid>
								</Grid>
							</ParallaxHover>
						</ScrollParallax>
					</Grid>
					<Grid
						item
						onClick={() => (window.location.href = "https://www.sattune.pt")}>
						<ScrollParallax strength={0.05} zIndex={0}>
							<ParallaxHover
								width={250}
								height={300}
								borderRadius={10}
								shine={1}
								rotation={9}>
								<Grid
									container
									direction="column"
									width={250}
									height={300}
									backgroundColor="#111"
									alignContent={"center"}
									justifyContent="space-around"
									paddingLeft={"18%"}>
									<Grid item>
										<img src={logoNexteam} width="75%"></img>
									</Grid>
								</Grid>
							</ParallaxHover>
						</ScrollParallax>
					</Grid>
				</Grid>
			</ScrollParallax>
		</Box>
	);
}
